<template>
	<div class="forget-password col" align-x="center">
		<quote-title label="忘記密碼" />
		<br>
		<br>
		<br>
		<el-form :model="ruleForm" size="small">
			<el-form-item prop="email" :rules="[{required:true, message:'請輸入信箱'}, {type:'email', message:'請輸入信箱'}]">
				<el-input v-model="ruleForm.email" placeholder="輸入信箱" class="round" />
			</el-form-item>
		</el-form>
		<br>
		<div class="center">
			<el-button type="theme" round @click="handleForgetPassword()">取得重設密碼信件</el-button>
		</div>
		<br>
		<br>
	</div>
</template>

<script>
import { mapActions } from "vuex";
export default {
	data() {
		return {
			ruleForm: new Object
		}
	},
	methods: {
		...mapActions("auth", ["_forgetPassword"]),
		async handleForgetPassword() {
			this.$message.success(await this._forgetPassword(this.ruleForm));
		}
	}
}
</script>

<style lang="scss" scoped>
.forget-password {
	padding: 50px 5%;
}
.el-form {
	width: 100%;
	max-width: 400px;
}
</style>